<template>
  <div>
    <div class="card">
      NewsdicUpdate {{perf}}
      <DataTable class="p-datatable-sm"
        ref="dt" :value="newsdics" dataKey="id" 
        :paginator="true" :rows="10" :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50,75,100]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
        responsiveLayout="scroll"
        stripedRows selectionMode="single">
        <template #header>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="キーワード" />
          </span>
        </template>
        <Column field="id" header="ID" :sortable="true" style="min-width:12rem"></Column>
        <Column field="dicname" header="辞書名" :sortable="true" style="min-width:16rem"></Column>
        <Column field="wordlist" header="単語リスト" :sortable="true" style="min-width:16rem"></Column>
        <Column :exportable="false" style="min-width:8rem">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editNewsdic(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog v-model:visible="newsdicDialog" :style="{width: '450px'}" header="ニュース辞書" :modal="true" class="p-fluid">
      <div class="field">
        <label for="dicname">辞書名：</label>{{newsdic.dicname}}
      </div>
      <div class="field">
        <label for="wordlist">単語リスト</label>
        <Textarea id="wordlist" v-model="newsdic.wordlist" required="true" rows="10" cols="20" />
      </div>
      <div class="field">
        <label for="passcode">パスコード</label>
        <InputText id="passcode" v-model.trim="newsdic.passcode" required="true" />
      </div>      
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
        <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveNewsdic" />
      </template>
    </Dialog>

  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import NewsdicUpdateService from '@/service/NewsdicUpdateService';
import axios from 'axios';

export default {
  data() {
    return {
      perf: null,
      newsdics: null,
      newsdicDialog: false,
      newsdic: {},
      filters: {},
      submitted: false
    }
  },
  newsdicUpdateService: null,
  created() {
    this.newsdicUpdateService = new NewsdicUpdateService();
    this.initFilters();
  },
  mounted() {
    this.newsdicUpdateService.getAll()
    .then(d => {
      this.newsdics = d.data;
      this.perf = d.perf;
    });
  },
  methods: {
    hideDialog() {
      this.newsdicDialog = false;
      this.submitted = false;
    },
    saveNewsdic() {
      this.submitted = true;

      let param_newsdic = {
        dicname: this.newsdic.dicname,
        wordlist: this.newsdic.wordlist,
        passcode: this.newsdic.passcode
      };

      axios.patch('https://api.tradersr.com/api/newsdicupdate/' + this.newsdic.id, param_newsdic)
      .then(res => {
        if(res.data.data){
          this.newsdic = res.data.data;
          this.newsdics[this.findIndexById(this.newsdic.id)] = this.newsdic;
        }
      })
      .catch(e => {
        console.log(e);
      })

      this.newsdicDialog = false;
      this.newsdic = {};
    },
    editNewsdic(newsdic) {
      this.newsdic = {...newsdic};
      this.newsdicDialog = true;
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.newsdics.length; i++) {
        if (this.newsdics[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>